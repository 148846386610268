<template>
  <div class="ui">
    <Transition name="slide-fade">
      <div v-if="showUI" class="ui--button">
        <button
          v-if="isMobile"
          @touchstart="startRecord()"
          @touchend="stopRecord()"
        >
          <Transition name="fade">
            <i v-if="!loading && !error" class="bx bx-microphone"></i>
          </Transition>
          <Transition name="fade">
            <i v-if="loading && !error" class="bx bx-loader bx-spin"></i>
          </Transition>
          <Transition name="fade">
            <i v-if="error" class="bx bx-message-square-error bx-tada"></i>
          </Transition>
        </button>
        <button
          v-if="!isMobile"
          @mousedown="startRecord()"
          @mouseup="stopRecord()"
        >
          <Transition name="fade">
            <i v-if="!loading && !error" class="bx bx-microphone"></i>
          </Transition>
          <Transition name="fade">
            <i v-if="loading && !error" class="bx bx-loader bx-spin"></i>
          </Transition>
          <Transition name="fade">
            <i v-if="error" class="bx bx-message-square-error bx-tada"></i>
          </Transition>
        </button>
        <h3>I'm DeeCee. Talk to me.</h3>
        <p>
          Hold mic button and start talking.<br />
          Release it to stop.
        </p>
      </div>
    </Transition>
    <Transition name="fade">
      <div class="ui--disclaimer" v-if="showStart">
        <button @click="start()">
          <Transition name="fade">
            <i v-if="!loading" class="bx bx-message-square-dots"></i>
          </Transition>
          <Transition name="fade">
            <i v-if="loading && !error" class="bx bx-loader bx-spin"></i>
          </Transition>
          <span>start</span>
        </button>
      </div>
    </Transition>
    <div class="ui--speechbubbles" v-if="false">
      <Transition name="slide-fade">
        <div v-if="userText" class="ui--bubble user">{{ userText }}</div>
      </Transition>
      <Transition name="slide-fade">
        <div v-if="npcText" class="ui--bubble npc">
          {{ npcText }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
let userTextInterval;
let npcTextInterval;

const applyEllipsis = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  } else {
    return text;
  }
};

const isMobileDevice = () => {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0
  );
};

export default {
  name: "ui",
  props: {
    manager: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isMobile: isMobileDevice(),
      showStart: true,
      showUI: false,
      loading: true,
      error: false,
      userText: "",
      npcText: "",
    };
  },
  methods: {
    start() {
      if (!this.loading) {
        this.manager.trigger("convai-init");
        this.manager.trigger("loader-hide");
        this.showStart = false;
      }
    },
    startRecord() {
      this.manager.trigger("convai-startrecord");
      this.userText = "";
      clearInterval(userTextInterval);
      clearInterval(npcTextInterval);
      setTimeout(() => {
        this.npcText = "";
      }, 200);
    },
    stopRecord() {
      this.loading = true;
      this.manager.trigger("convai-stoprecord");
    },
  },
  mounted() {
    this.manager.on("loader-ready", () => {
      this.loading = false;
    });

    this.manager.on("ui-setUserText", (userText) => {
      // this.loading = false;
      this.userText += userText;
      clearInterval(userTextInterval);

      userTextInterval = setInterval(() => {
        this.userText = "";
      }, 10000);
    });

    this.manager.on("ui-setNpcText", (npcText) => {
      this.loading = false;
      this.npcText = applyEllipsis(npcText, 110);
      clearInterval(npcTextInterval);

      npcTextInterval = setInterval(() => {
        this.npcText = "";
      }, 10000);
    });

    this.manager.on("ui-showUI", () => {
      this.manager.trigger("avatar-bow");
      this.showUI = true;
    });

    this.manager.on("ui-setError", () => {
      this.error = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.ui {
  position: relative;
  pointer-events: none;
  max-width: 520px;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: $z-ui;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    width: 85px;
    height: 85px;
    margin: auto;
    font-size: 42px;
    color: #fff;
    background: black;
    user-select: none;
    margin: auto;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    box-shadow: 0px 0px 15px 0px rgba(60, 60, 60, 0.5);

    &:active {
      transform: scale(0.95);
    }

    .bx-message-square-error,
    .bx-loader {
      position: absolute;
    }
  }

  h3 {
    font-weight: 700;
    margin-top: 25px;
    font-size: 24px;
  }

  p {
    position: relative;
    font-weight: 400;
    padding: 15px 60px 0 60px;
    text-align: center;
    color: white;
    margin-top: 10px;
    line-height: 1.45;

    &:before {
      display: block;
      position: absolute;
      content: "";
      background: white;
      height: 1px;
      top: 0;
      right: 40px;
      left: 40px;
    }
  }

  &--disclaimer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: $z-disclaimer;

    button {
      position: relative;
    }

    span {
      position: absolute;
      top: 90px;
      font-size: 32px;
      letter-spacing: 2px;
    }
  }

  &--button {
    position: absolute;
    bottom: 35px;
    right: 0;
    left: 0;
    z-index: $z-ui;
    color: white;
    text-align: center;
  }

  &--speechbubbles {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 25px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60vh;
    padding-bottom: calc(25px + 85px + 15px);
  }

  &--bubble {
    display: block;
    max-width: 68vw;
    line-height: 1.4;
    letter-spacing: 0.02rem;
    font-size: 17px;
    margin-bottom: 20px;
    width: fit-content;
    box-shadow: 0px 0px 15px 0px rgba(60, 60, 60, 0.5);

    &.user {
      padding: 15px 20px 13px 20px;
      margin-left: 25px;
      background: white;
      text-align: right;
      align-self: flex-end;
      color: #6d6994;
      border-radius: 20px 0px 20px 20px;
    }

    &.npc {
      margin-right: 25px;
      padding: 15px 20px 13px 20px;
      border-radius: 0px 20px 20px 20px;
      background: #7b70ee;
      color: #fafafd;
    }
  }
}
</style>
