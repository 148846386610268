export default [
  {
    name: "avatarModel",
    type: "gltfModel",
    path: "/models/avatar/deecee.glb",
    // path: "https://models.readyplayer.me/6617f98ae5acc0912ef9f15a.glb?morphTargets=Oculus%20Visemes,eyeBlinkLeft,eyeBlinkRight&textureQuality=high&useDracoMeshCompression=true",
  },
  {
    name: "backgroundImage",
    type: "image",
    path: "/assets/bg.jpg",
  },
  {
    name: "envMap",
    type: "cubeTexture",
    path: [
      "/env/0/px.png",
      "/env/0/nx.png",
      "/env/0/py.png",
      "/env/0/ny.png",
      "/env/0/pz.png",
      "/env/0/nz.png",
    ],
  },
];
