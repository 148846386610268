uniform float uAspectRatio;
uniform float uPixelSize;
uniform float uReveal;
uniform vec3 uColorOne;
uniform vec3 uColorTwo;

varying vec2 vUv;

float hashwithoutsine12(vec2 p){
  vec3 p3  = fract(vec3(p.xyx) * .1031);
    p3 += dot(p3, p3.yzx + 33.33);
    return fract((p3.x + p3.y) * p3.z);
}

float map(float value, float min1, float max1, float min2, float max2) {
  float val = min2 + (value - min1) * (max2 - min2) / (max1 - min1);
  return clamp(val, min2, max2);
}

void main() {
  vec2 gridSize = vec2(
    uPixelSize, 
    floor(uPixelSize / uAspectRatio)
  );

  vec2 newUV = floor(vUv * gridSize);

  float x = floor(vUv.x * 10.0);
  float y = floor(vUv.y * 10.0);
  float pattern = hashwithoutsine12(newUV);

  float w = 0.5;
  // float p0 = uProgress;

  float p0 = (clamp( (uReveal - 0.2*0.) / 0.8, 0.0, 1.0));
  float p1 = (clamp( (uReveal - 0.2 * 1.0) / 0.8, 0.0, 1.0));

  p0 = map(p0, 0.0, 1.0, - w,  1.0);
  p0 = smoothstep(p0, p0 + w, 1.0 - vUv.y);
  float p0_ = clamp(1. - 2.*p0 + pattern, 0.0, 1.0);

  p1  = map(p1, 0.0, 1.0, - w, 1.0);
  p1 = smoothstep(p1, p1 + w, 1.0 - vUv.y);
  float p1_ = clamp(1.0 - 2. * p1 + pattern, 0.0, 1.0);

  float mixValue = distance(vUv, vec2(0, 1));
  vec3 color = mix(uColorOne, uColorTwo, mixValue);

  vec3 finalColor = mix(uColorOne, color, p0_);

  // gl_FragColor = vec4(vec3(p0_, p1_, 0.0), p0_);

  gl_FragColor = vec4(finalColor, p0_);

  #include <tonemapping_fragment>
  #include <colorspace_fragment>
}
