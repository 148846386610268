import * as THREE from "three";
import Experience from "../Experience";

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.renderer = this.experience.renderer.instance;
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    this.options = {
      ambientLightColor: 0xffffff,
      sunLightColor: 0xfffbfb,
      environmentIntensity: 14.5,
    };

    // Setup
    this.setAmbientLight();
    this.setSunLight();
    this.setEnvMap();

    // Debug
    this.setDebug();
  }

  setAmbientLight() {
    this.ambientLight = new THREE.AmbientLight(
      this.options.ambientLightColor,
      0.1
    );
    this.ambientLight.visible = false;
    this.scene.add(this.ambientLight);
  }

  setSunLight() {
    this.sunLight = new THREE.DirectionalLight(this.options.sunLightColor, 1);
    this.sunLight.castShadow = true;
    this.sunLight.position.set(0.5, 0, 0.866);
    this.sunLight.visible = false;
    this.scene.add(this.sunLight);
  }

  setEnvMap() {
    this.envMap = this.resources.items.envMap;
    this.envMap.encoding = THREE.SRGBColorSpace;

    // this.scene.background = this.envMap;
    this.scene.environment = this.envMap;
    this.scene.environmentIntensity = this.options.environmentIntensity;
  }

  setDebug() {
    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("🔆 Env");
      this.debugFolder
        .add(this.scene, "environmentIntensity")
        .name("Env Intensity")
        .min(0)
        .max(30)
        .step(0.01);

      this.debugFolder
        .add(this.ambientLight, "intensity")
        .name("Ambient Intensity")
        .min(0)
        .max(10)
        .step(0.001);

      this.debugFolder
        .addColor(this.options, "ambientLightColor")
        .name("Light Color")
        .onChange(() => {
          this.ambientLight.color.set(this.options.ambientLightColor);
        });

      this.debugFolder.add(this.ambientLight, "visible").name("Toggle");

      this.debugFolder
        .add(this.sunLight, "intensity")
        .name("DirLight Intensity")
        .min(0)
        .max(10)
        .step(0.001);
      this.debugFolder
        .addColor(this.options, "sunLightColor")
        .name("Light Color")
        .onChange(() => {
          this.sunLight.color.set(this.options.sunLightColor);
        });
      this.debugFolder.add(this.sunLight, "visible").name("Toggle");

      this.helper = new THREE.DirectionalLightHelper(this.sunLight, 5);
      this.scene.add(this.helper);
    }
  }
}
