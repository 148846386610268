import * as THREE from "three";
import { gsap } from "gsap";
import Experience from "../Experience";

import vertexShader from "../../shaders/loader/vertex.glsl";
import fragmentShader from "../../shaders/loader/fragment.glsl";

export default class Loader {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.debug = this.experience.debug;
    this.manager = this.experience.manager;
    this.resources = this.experience.resources;
    this.sizes = this.experience.sizes;

    // Options
    this.options = {
      uColorOne: 0x371c2b,
      uColorTwo: 0x000000,
      uAlpha: 1,
    };

    // Setup
    this.setGeometry();
    this.setMaterial();
    this.setMesh();

    this.initEvents();

    //debug
    this.setDebug();
  }

  initEvents() {
    this.manager.on("loader-hide", this.hideLoader.bind(this));

    this.resources.on("ready", () => {
      this.manager.trigger("loader-ready");
    });
  }

  setGeometry() {
    this.geometry = new THREE.PlaneGeometry(2, 2, 1, 1);
  }

  setMaterial() {
    this.material = new THREE.ShaderMaterial({
      transparent: true,
      uniforms: {
        uAspectRatio: new THREE.Uniform(this.sizes.width / this.sizes.height),
        uPixelSize: new THREE.Uniform(40),
        uReveal: new THREE.Uniform(1),
        uColorOne: new THREE.Uniform(new THREE.Color(this.options.uColorOne)),
        uColorTwo: new THREE.Uniform(new THREE.Color(this.options.uColorTwo)),
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    });
  }

  setMesh() {
    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.renderOrder = 2;
    this.scene.add(this.mesh);
  }

  hideLoader() {
    gsap.to(this.material.uniforms.uReveal, {
      duration: 1.5,
      value: 0,
      onComplete: () => {
        this.manager.trigger("ui-showUI");
        this.destroy();
      },
    });
  }

  resize() {
    this.material.uniforms.uAspectRatio.value =
      this.sizes.width / this.sizes.height;
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("🌍 Loader");
      // this.debugFolder
      //   .add(this.material.uniforms.uReveal, "value")
      //   .min(0)
      //   .max(1)
      //   .step(0.01);
    }
  }

  destroy() {
    this.geometry.dispose();
    this.material.dispose();
    this.mesh.geometry.dispose();
    this.mesh.material.dispose();
    this.scene.remove(this.mesh);
    if (this.debug.active) {
      this.debugFolder.destroy();
    }
  }
}
