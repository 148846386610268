<script setup>
// Vue Components
import ui from "./components/ui.vue";

// Three.js app
import Experience from "./Experience/Experience";
const experience = new Experience();
const manager = experience.manager;
</script>

<template>
  <ui :manager="manager"></ui>
</template>

<style lang="scss">
#app {
  position: relative;
  width: 100vw;
  height: var(--vh);
  background-image: url("/assets/bg.jpg");
  background-size: cover;
  background-position: center;
}
</style>
