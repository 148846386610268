import * as THREE from "three";
import { ConvaiClient } from "convai-web-sdk";
import { lerpMorphTarget } from "./Utils/lerpMorphTarget";

import Experience from "./Experience";

// Ready Player Me lipsync code
const RPMViseme = {
  0: "viseme_sil",
  1: "viseme_PP",
  2: "viseme_FF",
  3: "viseme_TH",
  4: "viseme_DD",
  5: "viseme_KK",
  6: "viseme_CH",
  7: "viseme_SS",
  8: "viseme_NN",
  9: "viseme_RR",
  10: "viseme_AA",
  11: "viseme_E",
  12: "viseme_I",
  13: "viseme_O",
  14: "viseme_U",
};

// https://models.readyplayer.me/6617f98ae5acc0912ef9f15a.glb?morphTargets=viseme_sil,viseme_PP,viseme_FF,viseme_TH,viseme_DD,viseme_KK,viseme_CH,viseme_SS,viseme_NN,viseme_RR,viseme_AA,viseme_E,viseme_I,viseme_O,viseme_U&useDracoMeshCompression=true

export default class ConvAI {
  constructor() {
    this.experience = new Experience();
    this.time = this.experience.time;
    this.manager = this.experience.manager;

    // Setup
    this.isRecording = false;
    this.isTalking = false;
    this.faceData = [];
    this.facialData = [];
    this.blendShapes = [];
    this.convaiClient = null;
    this.currentBlendFrame = 0;
    this.blink = false;
    this.nextBlinkTime = 0;
    this.userText = "";
    this.npcText = "";

    this.initialized = false;

    // init Events
    this.initEvents();
  }

  initEvents() {
    this.manager.on("convai-init", this.onInit.bind(this));

    this.manager.on("convai-startrecord", this.onStartRecord.bind(this));
    this.manager.on("convai-stoprecord", this.onStopRecord.bind(this));
  }

  onInit() {
    this.avatar = this.experience.world.avatar.model;
    console.log(import.meta.env.VITE_CONVAI_CHARACTERID);
    this.convaiClient = new ConvaiClient({
      apiKey: import.meta.env.VITE_CONVAI_APIKEY,
      characterId: import.meta.env.VITE_CONVAI_CHARACTERID,
      enableAudio: true, // use false for text only.
      enableFacialData: true,
      faceModel: 3, // OVR lipsync
    });

    this.convaiClient.setResponseCallback((response) => {
      if (response.hasUserQuery()) {
        var transcript = response.getUserQuery();
        var isFinal = transcript.getIsFinal();
        if (isFinal) {
          this.userText = " " + transcript.getTextData();
          transcript = "";
          if (transcript) {
            this.userText = transcript.getTextData() + transcript.getTextData();
          } else {
            this.userText = this.userText;
            this.manager.trigger("ui-setUserText", this.userText);
            console.log(this.userText);
          }
        }
      }
      if (response.hasAudioResponse()) {
        var audioResponse = response?.getAudioResponse();
        this.npcText += " " + audioResponse.getTextData();
        this.manager.trigger("ui-setNpcText", this.npcText);

        if (audioResponse?.getVisemesData()?.array[0]) {
          //Viseme data
          let faceData = audioResponse?.getVisemesData().array[0];
          //faceData[0] implies sil value. Which is -2 if new chunk of audio is recieve.
          if (faceData[0] !== -2) {
            this.faceData.push(faceData);
            this.facialData = this.faceData;
          }
        }
      }
    });

    this.convaiClient.setErrorCallback((error, errorMessage) => {
      this.manager.trigger("ui-setError");
      this.manager.trigger("ui-setUserText", error);
      this.manager.trigger("ui-setNpcText", errorMessage);
    });

    this.convaiClient.onAudioPlay(() => {
      if (!this.isTalking) {
        this.isTalking = true;
        this.manager.trigger("avatar-talking");
      }
    });

    this.convaiClient.onAudioStop(() => {
      if (this.isTalking) {
        this.isTalking = false;
        this.manager.trigger("avatar-idle");
      }
    });

    this.initialized = true;
  }
  onStartRecord() {
    if (!this.isRecording) {
      this.isRecording = true;
      console.log("startRecord");
      this.npcText = "";
      this.convaiClient.startAudioChunk();
    }
  }

  onStopRecord() {
    if (this.isRecording) {
      this.isRecording = false;
      console.log("stopRecord");
      this.convaiClient.endAudioChunk();
    }
  }

  update() {
    if (!this.initialized) return;

    const elapsedTime = this.time.elapsedTime;

    // Blink management
    if (elapsedTime > this.nextBlinkTime) {
      this.blink = true;
      const duration = THREE.MathUtils.randFloat(170, 250); // Random Blink duration
      setTimeout(() => {
        this.blink = false;
      }, duration);
      this.nextBlinkTime = elapsedTime + THREE.MathUtils.randFloat(3, 7); // Random time for next blink between 3 and 7 seconds
    }

    const eyeBlinkIntensity = this.blink ? 1 : 0;
    lerpMorphTarget("eyeBlinkLeft", eyeBlinkIntensity, 0.5, this.avatar);
    lerpMorphTarget("eyeBlinkRight", eyeBlinkIntensity, 0.5, this.avatar);

    if (!this.isTalking) {
      this.currentBlendFrame = 0;
      this.facialData.forEach((data, index) => {
        Object.keys(data).forEach((key) => {
          lerpMorphTarget(RPMViseme[key], 0, 1, this.avatar); // Reset morph targets
        });
      });
      return;
    }

    const frameSkipNumber = 500;

    if (elapsedTime - this.currentBlendFrame > frameSkipNumber) {
      for (let i = 0; i < frameSkipNumber; i++) {
        this.blendShapes.push(0);
      }
      this.currentBlendFrame += frameSkipNumber;
    } else if (elapsedTime - this.currentBlendFrame < -frameSkipNumber) {
      this.blendShapes.splice(-frameSkipNumber);
      this.currentBlendFrame -= frameSkipNumber;
    }

    if (
      this.currentBlendFrame <= this.facialData.length &&
      this.facialData[this.currentBlendFrame]
    ) {
      const currentFacialData = this.facialData[this.currentBlendFrame];
      Object.keys(currentFacialData).forEach((viseme) => {
        lerpMorphTarget(
          RPMViseme[viseme],
          currentFacialData[viseme],
          1,
          this.avatar
        );
      });
      this.currentBlendFrame++;
    }
  }
}
