import Experience from "../Experience";
import Environment from "./Environment";
import Loader from "./Loader";
import Ground from "./Ground";
import Avatar from "./Avatar";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Wait for resources to be loaded
    this.loader = new Loader();

    this.resources.on("ready", () => {
      // Setup
      // this.ground = new Ground();
      this.avatar = new Avatar();
      this.environment = new Environment();
    });
  }

  resize() {
    if (this.loader) this.loader.resize();
  }

  update() {
    if (this.avatar) this.avatar.update();
    if (this.ground) this.ground.update();
  }
}
