import * as THREE from "three";
import Experience from "../Experience";

export default class Avatar {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.manager = this.experience.manager;
    this.resources = this.experience.resources;

    // Options
    this.options = {};

    // Setup
    this.resource = this.resources.items.avatarModel;
    // this.idleAnimation = this.resources.items.idleAnimation;
    // this.talkingAnimation = this.resources.items.talkingAnimation;
    // this.bowAnimation = this.resources.items.bowAnimation;

    this.actions = {};
    this.previousAction = null;
    this.activeAction = null;

    this.setModel();
    this.setAnimation();
    this.initEvents();

    // Debug
    this.setDebug();
  }

  initEvents() {
    this.manager.on("avatar-idle", () => {
      this.fadeToAction("Idle", 0.5);
    });

    this.manager.on("avatar-talking", () => {
      this.fadeToAction("Talking", 0.5);
    });
    this.manager.on("avatar-bow", () => {
      setTimeout(() => {
        // this.fadeToAction("Bow", 0.5);
      }, 100);
    });
  }

  setModel() {
    this.model = this.resource.scene;
    this.model.position.set(0, 0.4, 0);
    this.scene.add(this.model);
  }

  setAnimation() {
    const states = ["Idle", "Talking", "Bow"];

    this.animation = {};
    this.animation.mixer = new THREE.AnimationMixer(this.model);

    this.resource.animations.forEach((clip) => {
      const action = this.animation.mixer.clipAction(clip);
      this.actions[clip.name] = action;
    });

    this.activeAction = this.actions["Idle"];
    this.activeAction.play();
  }

  fadeToAction(name, duration) {
    this.previousAction = this.activeAction;
    this.activeAction = this.actions[name];

    if (this.previousAction !== this.activeAction) {
      this.previousAction.fadeOut(duration);
    }

    this.activeAction
      .reset()
      .setEffectiveTimeScale(1)
      .setEffectiveWeight(1)
      .fadeIn(duration)
      .play();
  }

  setDebug() {
    if (this.debug.active) {
      // this.debugFolder = this.debug.ui.addFolder("🧑‍💻 Avatar");
    }
  }

  update() {
    // Avatar animation
    if (this.animation) this.animation.mixer.update(this.time.delta * 0.001);
  }
}
